import "./CommercialCleaningServices.less"
import React from "react"
import { Row, Col, Typography } from "antd"
import CtasSection from "../CtasSection"
import Image from "../../get-image"
const { Text, Title } = Typography

const features = [
  `Office buildings, office parks, and individual business offices`,
  `Medical offices and patient facilities`,
  `Banks`,
  `Hotels, motels, and corporate apartment facilities`,
  `Daycare centers and schools`,
  `Churches`,
  `Government facilities`,
  `Retail stores, shopping centers, and malls`,
  `Restaurants and bars`,
  `New office / building construction`,
]

const CommercialCleaningServices = () => {
  return (
    <>
      <Row className="commercial-highlights fade-in">
        <Col xs={24}>
          <Row className="commercial-highlights__ul-title">
            <Col xs={24} style={{ textAlign: "center" }}>
              <Title level={2}>All-In-One Commercial Cleaning</Title>
            </Col>
          </Row>

          <Row justify="center" align="middle">
            
              <Col xs={22} lg={12}>
                <Image imageName="commercial_cleaning_img2.png" className="commercial-highlights__image" />
              </Col>
              
              <Col xs={22} lg={12}>
                <Text strong>
                  We are the true all-in-one janitorial solution for all
                  property types:
                </Text>
                <ul>
                  {features.map(feature => (
                    <li key={feature}>
                      <Text>{feature}</Text>
                    </li>
                  ))}
                </ul>
              </Col>
           
          </Row>

          <Row className="commercial-highlights__footer-text">
            <Col xs={24}>
              <Text>
                Our janitorial staff is carefully recruited and passes a
                rigorous background check. We educate each commercial cleaning
                service team on the unique needs of your facility before sending
                them to your work site. All DCG cleaning personnel are fully
                bonded to ensure the safety and security of your business.
              </Text>

              <br />
              <br />
              <Text>
                Call Denver Cleaning Group Inc. now at (303) 848-3884 for a free,
                no-obligation estimate on your property and learn why Denver
                businesses are counting on DCG as their preferred commercial
                cleaning service partner.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <CtasSection whiteBackground />
    </>
  )
}

export default CommercialCleaningServices
