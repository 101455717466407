import './CommercialServiceHighlights.less';

import React from 'react';
import { Col, Row } from "antd"
import CommercialCleaningServices from "../CommercialCleaningServices"

const CommercialServiceHighlights = () => {

  return (
    <Row>
      <Col xs={24}>
        <CommercialCleaningServices />
      </Col>
    </Row>
  );
};

export default CommercialServiceHighlights;