import React from "react"
import { Row, Col } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CommercialServiceHighlights from "../components/CommercialServiceHighlights"
import HighlightsHeader from "../components/HighlightsHeader"
import Reviews from "../components/Reviews"
import Image from "../get-image"

// import "../styles/ServiceHighlights.less"

const CommersialHomePage = () => (
  <Layout className="home" ctaTarget="/quotes/commercial-cleaning">
    <SEO title="Home" />
    <div className="highlights">
      <Row justify="center" className="fade-in">
        <Col xs={22} lg={12}>
          <Row className="highlights">
            <Col xs={24}>
              <HighlightsHeader type="commercial" />
            </Col>
          </Row>
        </Col>

        <Col xs={0} lg={10}>
          <Image
            imageName="commercial_cleaning_img.png"
            className="highlights__head-image"
          />
        </Col>
      </Row>

      <Reviews />

      <CommercialServiceHighlights />
    </div>
  </Layout>
)

export default CommersialHomePage
